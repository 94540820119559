import moment from 'moment';
import 'moment-timezone';

export const parseDateStr = (s: string) => 
  readTimeStamp(s)?.format("MM/DD/YYYY hh:mm");

export const parseDate = (s: string) => 
  readTimeStamp(s)?.toDate();

function readTimeStamp(s: string): moment.Moment {

  moment().tz('America');
  const m = moment(s, 'DDMMYYYYHHmmss','us');
  return m.isValid() ? m : null;
}