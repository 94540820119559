import { AppBar, Container, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

interface TopMenuProps {
  onMenuClick: () => void,
  open: boolean,
  drawerWidth: number
}

const TopMenu = ({onMenuClick, open, drawerWidth} : TopMenuProps) => {
  return (
    <AppBar position="fixed" sx={{height: '5rem', ...(open && { pl: `${drawerWidth}px` })}}>
      <Container maxWidth={false} disableGutters sx={{ pl: '1rem' }}>
        <Toolbar
          disableGutters
          sx={{
            height: '100%',
            pt: '0.25rem',
            pb: '0.25rem',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Container
            disableGutters
            className="icon-text-container"
            maxWidth={false}
            sx={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onMenuClick}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src="icon.png"
              style={{ height: '4.25rem', margin: '0rem' }}
            />
            <Container
              disableGutters
              style={{
                justifyContent: 'space-around',
                display: 'flex',
                flexDirection: 'column'
              }}
              maxWidth={false}
            >
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  ml: 2,
                  fontFamily: 'sans-serif',
                  color: 'text.primary',
                  textDecoration: 'none',
                }}
              >
                AC Circuit Analyzer Analytics
              </Typography>
            </Container>
          </Container>

          <Tooltip title="Logout">
            <LogoutIcon sx={{ fontSize: '1.9rem', mr: '1.25rem' }} />
          </Tooltip>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopMenu;
