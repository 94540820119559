import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

const firebaseConfig = {
  apiKey: "AIzaSyAKcLUEDRCip5r1lzJ8e4YS8ixabubJU5w",
  authDomain: "ac-circuit-analyzer-a7d29.firebaseapp.com",
  databaseURL: "https://ac-circuit-analyzer-a7d29.firebaseio.com",
  projectId: "ac-circuit-analyzer-a7d29",
  storageBucket: "ac-circuit-analyzer-a7d29.appspot.com",
  messagingSenderId: "341396044798",
  appId: "1:341396044798:web:3039ca76739de449e25eaf",
  measurementId: "G-CWD2X0WMHG"
};


firebase.initializeApp(firebaseConfig);

export default firebase.database();
