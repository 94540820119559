import { Box } from '@mui/material';
import { UsersContext } from '../../contexts/UsersContext';
import CloudwatchPage from '../Cloudwatch/CloudwatchPage';

function Events() {
  const dashboardLink = "https://cloudwatch.amazonaws.com/dashboard.html?dashboard=Prod-Errors-Dashboard&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTg3NTA3NzQ2MTM4OCIsIlUiOiJ1cy1lYXN0LTFfYWVyRGNvNVhGIiwiQyI6Ijc3cmlsdGdxYXBndWZxNnRubGpkNGR0NDFuIiwiSSI6InVzLWVhc3QtMToxZDUyYzVlYy1hNGM5LTQ3M2ItOTE0Yi0zODIwMDU1NzRjYjIiLCJPIjoiYXJuOmF3czppYW06Ojg3NTA3NzQ2MTM4ODpyb2xlL3NlcnZpY2Utcm9sZS9DV0RCU2hhcmluZy1SZWFkT25seUFjY2Vzcy1KQVRCSDhNRCIsIk0iOiJVc3JQd1NpbmdsZSJ9"
  return (<CloudwatchPage
    title="User Errors"
    dashboardLink={dashboardLink}
  />)
}

export default Events;
