import './App.css';
import TopMenu from '../Menus/TopMenu';
import { createTheme, ThemeProvider } from '@mui/material';
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LeftMenu from '../Menus/LeftMenu';
import UsersList from '../UserAnalytics/UsersList';
import { createContext, useEffect, useState } from 'react';
import usersService from '../../services';
import { UsersContext } from '../../contexts/UsersContext';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from '../LandingPage';
import NoPage from '../NoPage';
import Events from '../UserAnalytics/Events';
import Errors from '../UserAnalytics/Errors';
import UserPath from '../UserAnalytics/UserPath';
import UserPathQuick from '../UserAnalytics/UserPathQuick';
import TutorialDashboard from '../UserAnalytics/Tutorial';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

function App() {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Update the document title using the browser API
    usersService.getAll().then((usersResp)=>setUsers(usersResp));
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#313131',
      },
      secondary: {
        main: '#ffffff'
      },
      text: {
        primary: '#ffffff',
        secondary: '#46505A',
      },
    }
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <UsersContext.Provider value={users}>
        <Box sx={{ display: 'flex' }}>
        <BrowserRouter>
          <TopMenu onMenuClick={handleDrawerOpen} open={open} drawerWidth={drawerWidth}/>
          <LeftMenu drawerWidth={drawerWidth} open={open} handleDrawerClose={handleDrawerClose}/>
          <Main open={open} sx={{mt:'4rem'}}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="userslist" element={<UsersList />} />
                <Route path="events" element={<Events />} />
                <Route path="userErrors" element={<Errors />} />
                <Route path="userPath" element={<UserPath />} />
                <Route path="userPathQuick" element={<UserPathQuick />} />
                <Route path="tutorial" element={<TutorialDashboard />} />
                <Route path="*" element={<NoPage />} />
              </Routes>
          </Main>
        </BrowserRouter>
        </Box>
      </UsersContext.Provider>
    </ThemeProvider>
  );
}


export default App;
