import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { parseDateStr } from '../../utils';
import { UsersContext } from '../../contexts/UsersContext';
import { ACCASession } from '../../types';

const columns: any[] = [
  { field: 'id', headerName: 'ID', width: 175 },
  { field: 'creationDateFormated', headerName: 'Creation Date', width: 190,
    valueGetter: (params: GridValueGetterParams) =>
      (params.row.creationDate as Date|null)?.toLocaleString()
  },
  { field: 'mostRecentSession', headerName: 'Most Recent Session', width: 190,
    valueGetter: (params: GridValueGetterParams) =>
      (params.row.meta.mostRecentSession as Date|null)?.toLocaleString()
  },
  {
    field: 'numSessions',
    headerName: 'Sessions',
    width: 100,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.sessions ? params.row.sessions.length : '0',
  },
  {
    field: 'numErrors',
    headerName: 'Errors',
    width: 100,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.errors ? Object.keys(params.row.errors).length: '0',
  },
  {
    field: 'numCircuits',
    headerName: 'Circuits',
    width: 100,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.circuits ? Object.keys(params.row.circuits).length: '0',
  },
];

function UsersList() {
  return (  
    <UsersContext.Consumer>
      {value => 
        <Box sx={{ height: '80vh', width: 900 }}>
          <DataGrid
            rows={value}
            columns={columns}
            pageSize={10}
            sx={{
              bgcolor: 'secondary'
            }}
            disableSelectionOnClick
          />
        </Box>
      }
    </UsersContext.Consumer>
  );
}

export default UsersList;
