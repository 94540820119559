import { Drawer, IconButton, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { People } from "@mui/icons-material";
import ListIcon from '@mui/icons-material/List';
import RepeatIcon from '@mui/icons-material/Repeat';
import BugReportIcon from '@mui/icons-material/BugReport';
import OutboundIcon from '@mui/icons-material/Outbound';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { useNavigate } from "react-router-dom";
import HikingIcon from '@mui/icons-material/Hiking';
import BlindIcon from '@mui/icons-material/Blind';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface LeftMenuProps {
  drawerWidth: number,
  open: boolean,
  handleDrawerClose: ()=>void
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const userAnalyticsItems = [
  { icon: <ListIcon />, label: 'User Table', route: 'userslist' },
  { icon: <RepeatIcon />, label: 'Events', route: 'events' },
  { icon: <OutboundIcon />, label: 'Errors', route: 'userErrors'  },
  { icon: <HikingIcon />, label: 'User Path', route: 'userPath'  },
  { icon: <BlindIcon />, label: 'User Path QuickView', route: 'userPathQuick'  },
  { icon: <HelpOutlineIcon />, label: 'Tutorial Dashboard', route: 'tutorial'  },
]

const errorItems = [
  { icon: <ListIcon />, label: 'Errors List' },
  { icon: <BugReportIcon />, label: 'Error Reports' }
]

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  bgcolor: 'primary.main'
}));

const LeftMenu = ({drawerWidth, open, handleDrawerClose} : LeftMenuProps) => {
  const navigate = useNavigate();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          bgcolor: 'primary.main'
        },
        color: 'primary.main'
      }}
      style={{color: '#ff0000'}}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader sx={{bgcolor: 'primary.main'}}>
        <IconButton onClick={handleDrawerClose}>
          {/* {direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
          <ChevronLeftIcon sx={{color: 'rgba(255,255,255,.8)'}}/>
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Accordion sx={{bgcolor: 'primary.main'}} disableGutters defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color: 'rgba(255,255,255,.8)'}}/>}
        >
          <People />
          <Typography sx={{ml: '0.75rem'}}>User Analytics</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{p:0}}>
          <List sx={{bgcolor: 'primary.main'}}>
            {userAnalyticsItems.map((item, index) => (
              <ListItem key={item.label} sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}>
                <ListItemButton onClick={()=>navigate(item.route)}>
                  {item.icon}
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                    sx={{ml:'1rem'}}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion sx={{bgcolor: 'primary.main'}} disableGutters defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color: 'rgba(255,255,255,.8)'}}/>}
        >
          <NewReleasesIcon/>
          <Typography sx={{ml: '0.75rem'}}>Errors</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{p:0}}>
          <List sx={{bgcolor: 'primary.main'}}>
            {errorItems.map((item, index) => (
              <ListItem key={item.label} sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}>
                <ListItemButton>
                  {item.icon}
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                    sx={{ml:'1rem'}}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </Drawer>
  );
}

export default LeftMenu;
