import firebase from "../firebase";
import { ACCASession, User } from "../types";
import { parseDate } from "../utils";
const db = firebase.ref("/users");

class UserDataService {
  getAll():Promise<User[]> {
    return new Promise((resolve, reject) => {
      db.on("value", (resp) => {
        const data = resp.val();
        const usersResp = Object.keys(data).map((id) => {
          const userPartial:Omit<User, 'meta'> = {
            id,
            sessions: data[id]?.ACCASessions ? this.buildSessions(data[id].ACCASessions) : [],
            errors: data[id]?.Errors,
            creationDate: data[id]?.creationDate ? parseDate(data[id]?.creationDate) : null,
            runsLeft: data[id]?.runsLeft,
            circuits: data[id]?.Circuits
          };
          const user: User = {
            ...userPartial,
            meta: {
              mostRecentSession: this.mostRecentSession(userPartial.sessions)
            }
          };
          return user;
        });
        usersResp.sort((a,b)=>{
          const aDate=Math.max(
            a?.creationDate?.getTime()||0,
            a?.meta?.mostRecentSession?.getTime()||0
          );
          const bDate=Math.max(
            b?.creationDate?.getTime()||0,
            b?.meta?.mostRecentSession?.getTime()||0
          );
          return bDate-aDate;
        });
        resolve(usersResp);
      }) 
    })
  }

  private buildSessions(rawSessions: {[sessionId: string]: string}): ACCASession[] {
    return Object.keys(rawSessions).map(sessionId => ({
      id: sessionId,
      startedAt: parseDate(sessionId.substring(0,14)),
      events: rawSessions[sessionId].split(',').map((x)=>parseInt(x))
    }));
  }

  private mostRecentSession(sessions: ACCASession[]) {
    let mostRecent = null;
    sessions.forEach(s=>{
      if (!mostRecent || s.startedAt > mostRecent) {
        mostRecent = s.startedAt;
      }
    });
    return mostRecent;
  }
}

export default new UserDataService();