import { Box } from '@mui/material';
import { UsersContext } from '../../contexts/UsersContext';
import { ReactElement } from 'react';

interface CloudwatchPageProps {
  title: string;
  dashboardLink: string;
  height?: string;
}

function CloudwatchPage({ title, dashboardLink, height }: CloudwatchPageProps): ReactElement {
  const heightFinal = height ?? '80vh'
  
  return (
    <UsersContext.Consumer>
      {value => 
        <Box sx={{ height: { heightFinal }, width: 900 }}>
          <a 
            href={ dashboardLink }
            style={{color: 'white', padding: '10px' }}>
            Direct Link
          </a>
          <iframe 
            src={dashboardLink} 
            title={title}
            width="100vw"
            height="100%"
            style={{width: '95vw', height: heightFinal, margin: '0', padding: '0'}}></iframe>
        </Box>
      }
    </UsersContext.Consumer>
  )
}

export default CloudwatchPage;