import CloudwatchPage from '../Cloudwatch/CloudwatchPage';
import { ReactElement } from 'react';

function TutorialDashboard(): ReactElement {
  const dashboardLink = "https://cloudwatch.amazonaws.com/dashboard.html?dashboard=Prod-Tutorial-Dashboard&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTg3NTA3NzQ2MTM4OCIsIlUiOiJ1cy1lYXN0LTFfYWVyRGNvNVhGIiwiQyI6Ijc3cmlsdGdxYXBndWZxNnRubGpkNGR0NDFuIiwiSSI6InVzLWVhc3QtMToxZDUyYzVlYy1hNGM5LTQ3M2ItOTE0Yi0zODIwMDU1NzRjYjIiLCJPIjoiYXJuOmF3czppYW06Ojg3NTA3NzQ2MTM4ODpyb2xlL3NlcnZpY2Utcm9sZS9DV0RCU2hhcmluZy1SZWFkT25seUFjY2Vzcy1SREIxSVI1USIsIk0iOiJVc3JQd1NpbmdsZSJ9&start=PT7D&end=null"
  return (<CloudwatchPage
    title="Tutorial Dashboard"
    dashboardLink={dashboardLink}
  />)
}

export default TutorialDashboard;
